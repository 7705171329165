/* CombinedRanking.css */

/* Table container */
.ag-theme-alpine {
  height: 100%; /* Use full height of the container */
  width: 100%; /* Use full width of the container */
  margin: 10px; /* Add margin to create space around the table */
}

/* Table header */
.ag-header-cell {
  background-color: #f5f5f5; /* Light background color */
  border-bottom: 1px solid #ccc; /* Light border */
  color: #333; /* Dark text color */
  font-weight: bold;
  font-size: 14px; /* Adjust font size */
}

/* Table rows */
.ag-row {
  border-bottom: 1px solid #ccc; /* Light border */
}

/* Table cells */
.ag-cell {
  padding: 8px; /* Adjust padding */
  font-size: 14px; /* Adjust font size */
}

/* Highlight every other row for better readability */
.ag-row:nth-child(even) {
  background-color: #f9f9f9; /* Light background color */
}