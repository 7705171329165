/* DraggableListPage.css */

.draggable-list-page {
  background-color: #ffcccb; /* Romantic pink */
}

/* Common styles */
.list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* List item styles */
.list-container > div {
  background-color: #fff; /* White background for list items */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow effect */
  padding: 12px 20px; /* Adjust padding for better spacing */
  margin-bottom: 10px; /* Spacing between list items */
  width: 90%; /* Adjust width */
}

/* Draggable styles */
.list-container > div:hover {
  cursor: grab; /* Change cursor to indicate draggable */
}

/* iPhone styles */
@media only screen and (max-width: 768px) {
  /* Background color */
  body {
    background-color: #ffcccb; /* Romantic pink */
  }

  /* List item styles */
  .list-container > div {
    padding: 12px 20px; /* Adjust padding for better spacing */
    margin-bottom: 10px; /* Spacing between list items */
    width: 90%; /* Adjust width */
  }
}

/* MacBook styles */
@media only screen and (min-width: 1200px) {
  /* Background color */
  body {
    background-color: #ffcccb; /* Romantic pink */
  }

  /* List item styles */
  .list-container > div {
    padding: 20px 40px; /* Adjust padding for better spacing */
    margin-bottom: 20px; /* Spacing between list items */
    width: 50%; /* Adjust width */
  }
}

/* Modal Container */
.Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Modal Content */
.ModalContent {
  background-color: #f5f5f5; /* Light background color */
  border-radius: 10px;
  padding: 20px;
  max-width: 90%;
  width: 300px; /* Adjust width as needed */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
}

/* Modal Title */
.ModalTitle {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/* Username Input */
.UsernameInput {
  width: 92%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px;
  font-size: 1rem;
}

/* Submit Button */
.SubmitButton {
  width: 100%;
  padding: 10px;
  background-color: chocolate; /* Chocolate color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.SubmitButton:hover {
  background-color: #8b4513; /* Darker chocolate color on hover */
}

.info-box {
  padding: 10px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center; /* Add this line to center the text horizontally */
}

.list-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-right: 30px; /* Add padding to the right */
}

.item-medal {
  position: absolute;
  right: 10px; /* Adjust the value as needed */
  top: 50%; /* Vertically center the medal emoji */
  transform: translateY(-50%); /* Adjust for vertical centering */
}
